<template>
  <div class="pane_container" id="screenHome">
    <el-row>
      <div id="RealTimeWaterLevel" :style="{width: '100%', height: chartHeight + 'px',}"></div>
    </el-row>
    <el-row style="margin-top: 10px;">
      <div id="RealTimeTraffic" :style="{width: '100%', height: chartHeight + 'px',}"></div>
    </el-row>
  </div>
</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import * as echarts from 'echarts';
  export default {
    name: 'fxtb',//分析图表
    components: {
      
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        loading:false,//遮罩
        RealTimeWaterLevel:'',//顶部折现图分站每日水量统计-用于屏幕放大缩小重新渲染
        RealTimeTraffic:'',//顶部折现图分站月度水量统计-用于屏幕放大缩小重新渲染
      }
    },
    watch: {
      
    },
    computed: {
      chartHeight() {
        let ele = document.getElementById("screenHome");
        if(ele){
          var allHeight = ele.offsetHeight - 150 //减去顶部标题+padding，减去中部标题+padding，减去中部日期行
          return Math.ceil(allHeight/2)
        }else{
          return 350
        }
      },
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      this.getAllData()
      window.onresize = () => {
        this.RealTimeWaterLevel.resize();
        this.RealTimeTraffic.resize();
        this.$forceUpdate()
      };
    },
    methods: {
      // 获取所有数据-ehcatrs
      getAllData(){
        this.getDailyChart();//分站每日水量统计
        this.getMonthlyChart();//获取分站月度水量统计
      },
      // 分站每日水量统计
      getDailyChart(){
        var d1,d2,xDatas
        var data={
          isTotalMeter:this.isTotalMeter
        }
        // basicDataApi.homeGetRealChart(data).then(response => {
          // if(response.code == 200){
            // xDatas = response.result.xDatas
            // d1 = response.result.y1Datas
            // d2 = response.result.y2Datas
            xDatas = ['2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19',]
            d1 = ['10','8','14','22','5','11','14','20','28','34','32','25','31','34','14','22','5','11','14','20','28','34','32','25','31','34','14','22',]
            d2 = ['20','28','34','32','25','31','34',]
            // 最近30分钟访问情况
            this.RealTimeWaterLevel = echarts.init(document.getElementById('RealTimeWaterLevel'));
            this.RealTimeWaterLevel.setOption({
              title: {
                text: '实时水位曲线图'
              },
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                top:'15%',
                bottom:'10%',
                right:'10%'
              },
              legend: {
                data: ['实时水位',],
                x:'right',      //可设定图例在左、右、居中
                y:'top',     //可设定图例在上、下、居中
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,//坐标轴两边留白
                  axisTick: {show: false},
                  data: xDatas
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name:'实时水位',
                  type: 'line',
                  itemStyle: {
                    color:'#c09b31',
                  },
                  lineStyle: {
                    color: "#c09b31",
                    width:1
                  },
                  areaStyle: { 
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(192,155,49,0.3)'},{offset: 1,color: 'rgba(192,155,49,1)'}]),
                  },
                  stack: 'Total',
                  data: d1,
                  smooth: true
                },
                // {
                //   name:'未抄表数',
                //   type: 'line',
                //   itemStyle: {
                //     color:'#b694f0',
                //   },
                //   lineStyle: {
                //     color: "#b694f0",
                //     width:1
                //   },
                //   areaStyle: { 
                //     color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(182,148,240,0.3)'},{offset: 1,color: 'rgba(182,148,240,1)'}]),
                //   },
                //   stack: 'Total',
                //   data: d2,
                //   smooth: true
                // },
              ]
            })
        //   }else{
        //     xDatas = ''
        //     d1 = ''
        //     d2 = ''
        //     this.$notify({
        //       message: response.message,
        //       type: 'warning',
        //       duration: 2000
        //     });
        //   }
        // }).catch(()=>{})
      },
      // 分站月度水量统计
      getMonthlyChart(){
        var d1,d2,xDatas
        var data={
          isTotalMeter:this.isTotalMeter
        }
        // basicDataApi.homeGetRealChart(data).then(response => {
          // if(response.code == 200){
            // xDatas = response.result.xDatas 
            // d1 = response.result.y1Datas 
            // d2 = response.result.y2Datas 
            xDatas = ['2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19','2023-12-13','2023-12-14','2023-12-15','2023-12-16','2023-12-17','2023-12-18','2023-12-19',]
            d1 = ['20','28','34','32','25','31','34','10','8','14','22','5','11','14','34','32','25','31','34','10','8','8','14','22','5','11','14','34',]
            d2 = ['10','8','14','22','5','11','14',]
            // 最近30分钟访问情况
            this.RealTimeTraffic = echarts.init(document.getElementById('RealTimeTraffic'));
            this.RealTimeTraffic.setOption({
              title: {
                text: '实时流量曲线图'
              },
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                top:'15%',
                bottom:'10%',
                right:'10%'
              },
              legend: {
                data: ['实时流量'],
                x:'right',      //可设定图例在左、右、居中
                y:'top',     //可设定图例在上、下、居中
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,//坐标轴两边留白
                  axisTick: {show: false},
                  data: xDatas
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name:'实时流量',
                  type: 'line',
                  itemStyle: {
                    color:'#1a96d4',
                  },
                  lineStyle: {
                    color: "#1a96d4",
                    width:1
                  },
                  areaStyle: { 
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(26, 150, 212,0.3)'}, { offset: 1,color: 'rgba(26, 150, 212,1)'}]),
                  },
                  stack: 'Total',
                  data: d1,
                  smooth: true
                },
                // {
                //   name:'未抄表数',
                //   type: 'line',
                //   itemStyle: {
                //     color:'#b694f0',
                //   },
                //   lineStyle: {
                //     color: "#b694f0",
                //     width:1
                //   },
                //   areaStyle: { 
                //     color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(182,148,240,0.3)'},{offset: 1,color: 'rgba(182,148,240,1)'}]),
                //   },
                //   stack: 'Total',
                //   data: d2,
                //   smooth: true
                // },
              ]
            })
        //   }else{
        //     xDatas = ''
        //     d1 = ''
        //     d2 = ''
        //     this.$notify({
        //       message: response.message,
        //       type: 'warning',
        //       duration: 2000
        //     });
        //   }
        // }).catch(()=>{})
      },
    },
  }

</script>

<style lang="scss" scoped>
.screenHome{
  min-height: calc(100vh - 45px - 35px - 20px);
}
</style>
