var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pane_container", attrs: { id: "screenHome" } },
    [
      _c("el-row", [
        _c("div", {
          style: { width: "100%", height: _vm.chartHeight + "px" },
          attrs: { id: "RealTimeWaterLevel" },
        }),
      ]),
      _c("el-row", { staticStyle: { "margin-top": "10px" } }, [
        _c("div", {
          style: { width: "100%", height: _vm.chartHeight + "px" },
          attrs: { id: "RealTimeTraffic" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }